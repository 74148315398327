<script lang="tsx">
import type { SlotsType, VNode } from 'vue'
import type { BaseUiInputProps } from '../BaseUiInput.vue'
import type { _BaseUiFormGroupProps, BaseUiFormGroupSlots } from '../form/BaseUiFormGroup.vue'
import type { BaseVariants, SizeProp } from '@core-types/components'
import { BaseUiInput, BaseUiFormGroup } from '#components'
import type { FormFieldObject } from '@core-types/form'
import { getBaseUiInputRuntimeProps } from '../BaseUiInput.vue'
import { getBaseUiFormGroupRuntimeProps } from '../form/BaseUiFormGroup.vue'

// only the new props
type _BaseUiFormInputProps = {
    inputClass?: string
}

export type BaseUiFormInputProps<Colors extends string, Variants extends string, Sizes extends string> =
    _BaseUiFormInputProps & Omit<BaseUiInputProps<Colors, Variants, Sizes>, 'descriptionId'> & _BaseUiFormGroupProps

type BaseUiFormInputSlots<Colors extends string, Variants extends string, Sizes extends string> = Omit<BaseUiFormGroupSlots, 'default'> & {
    default: {}
}

type ComponentOptions = {}

export function defineComponentBaseUiFormInput<
    Colors extends string,
    Variants extends string = BaseVariants,
    Sizes extends string = SizeProp,
>(options?: ComponentOverrideOptions<ComponentOptions, BaseUiFormInputProps<Colors, Variants, Sizes>, BaseUiFormInputSlots<Colors, Variants, Sizes>>) {
    const { descriptionId, ...BaseUiInputRuntimeProps } = getBaseUiInputRuntimeProps(options)

    return defineComponent(
        (props: BaseUiFormInputProps<Colors, Variants, Sizes>, ctx) => {

            const parentScope = getScopeIdAttr()

            return () => (
                <BaseUiFormGroup
                    form={props.form}
                    help={props.help}
                    descriptionAbove={props.descriptionAbove}
                    descriptionBelow={props.descriptionBelow}
                    hideRequired={props.hideRequired}
                    label={props.label}
                    noLabelElement={props.noLabelElement}
                    errorId={props.errorId}
                    descriptionId={props.descriptionId}
                    disabled={props.disabled}
                    loading={props.loading}
                    required={props.required}
                    id={props.id}
                    ariaLabel={props.ariaLabel}
                    ariaInvalid={props.ariaInvalid}
                >
                    {(slotData: BaseUiFormGroupSlots['default']) => [
                        slotData.renderLabel(
                            (
                                renderSlot(ctx.slots.default, options?.slots?.default, {}, (
                                    <>
                                        {props.label}
                                    </>
                                ))
                            ) as VNode,
                            {
                                hasDefaultSlotContent: ctx.slots.default !== undefined || !!options?.slots?.default,
                            }
                        ),

                        slotData.renderAboveDescription(),

                        (
                            <BaseUiInput
                                modelValue={props.modelValue}
                                onUpdate:modelValue={(val: string | number) => ctx.emit('update:modelValue', val)}
                                modelModifiers={props.modelModifiers}
                                form={props.form}
                                onUpdate:form={(val: FormFieldObject<string | number>) => ctx.emit('update:form', val)}
                                formModifiers={props.formModifiers}

                                id={slotData.inputId}
                                // TODO: remove later
                                class={props.inputClass}

                                // @ts-ignore
                                color={props.color}
                                // @ts-ignore
                                variant={props.variant}
                                // @ts-ignore
                                size={props.size}

                                type={props.type}
                                autocomplete={props.autocomplete}
                                placeholder={props.placeholder}
                                disabled={props.disabled}
                                loading={props.loading}
                                required={slotData.isInputRequired}
                                min={props.min}
                                max={props.max}
                                step={props.step}
                                autofocus={props.autofocus}
                                clearable={props.clearable}
                                showPasswordToggle={props.showPasswordToggle}
                                autoresize={props.autoresize}
                                rows={props.rows}
                                paddingBehavior={props.paddingBehavior}
                                square={props.square}
                                actions={props.actions}

                                descriptionId={slotData.inputDescriptionIds}
                                ariaInvalid={slotData.isInputAriaInvalid}

                                {...parentScope}
                            />
                        ),

                        slotData.renderBelowDescriptionAndError(),

                    ]}
                </BaseUiFormGroup>
            )
        },
        {
            props: {
                ...defineRuntimeProps<_BaseUiFormInputProps>({
                    inputClass: { type: String },
                }, options),
                ...BaseUiInputRuntimeProps,
                ...getBaseUiFormGroupRuntimeProps(options),
            },
            slots: Object as SlotsType<BaseUiFormInputSlots<Colors, Variants, Sizes>>,
            emits: {
                'update:modelValue': (val: string | number) => true,
                'update:form': (val: FormFieldObject<string | number>) => true,
            },
        }
    )
}

export default defineComponentBaseUiFormInput()

</script>

<style lang="scss" scoped>
@use "@core-scss/components/BaseUiFormInput.scss" as *;

</style>
